(function ($) {

    var launching = false;

    var launchCannedSession = function(cannedLauncher) {
        if (launching) {
            return;
        }

        launching = true;

        var params = [];

        // CSRF
        params.push('_token=' + $.getCSRFToken());

        // Current tab
        var currentTab = cannedLauncher.find('.panel.tabbed .tab.active');

        // Selected template
        var selectedTemplate = currentTab.find('.checkbox.checked');
        console.log('selectedTemplate'+selectedTemplate);
        if (selectedTemplate.length > 0 ) {
            var selectedTemplateID = selectedTemplate.attr('data-exam-template-id');

            if (selectedTemplateID) {
                params.push('template_id=' + selectedTemplateID);
            }

            $.ajax({
                url: '/instructor/actions/launch',
                method: 'POST',
                data: params.join('&')
            }).done(function(data) {
                launching = false;

                window.location.href = '/exam/session';
            }).fail(function(xhr) {
                launching = false;

                // Handle no session error
                if (xhr.status === 401) {
                    window.alert('Your session is no longer active. Please sign in again.');
                    window.location.reload();
                    return;
                }

                // Handle unexpected server error
                if (xhr.status === 500) {
                    window.alert('An unexpected server error occurred. Please try again.');
                    return;
                }

                alert('The exam template you selected was not found. Please try a different template.')
            });
        }
        else {
            alert('Please select an exam');
            return;
        }
    };

    $('.widget.canned-launcher').each(function(index, cannedLauncher) {
        var cannedLauncher = $(cannedLauncher);

        $('.action-bar .launch-canned-session a.launch').off().on('click touchend', function(e) {
            e.preventDefault();

            launchCannedSession(cannedLauncher);
        });
    });

})(jQuery);
