(function ($) {

    $('.action-bar .exam-review .review a').off().on('click touchend', function(e) {
        e.preventDefault();

        var modal = $('.section.review-modal');
        var filtersPopup = $('.section.review-filters');
        var reviewNav = $('.action-bar .exam-review .actions');

        if (modal.hasClass('active')) {
            modal.removeClass('active');
            reviewNav.show();
        } else if (filtersPopup.hasClass('active')) {
            filtersPopup.removeClass('active');
            reviewNav.show();
        } else {
            filtersPopup.addClass('active');
        }
    });

    $('.action-bar .exam-review .actions .previous').off().on('click touchend', function(e) {
        e.preventDefault();

        $(this).addClass('active');

        // Get previous URL
        var previousURL = $(this).attr('data-previous-url');

        // Navigate
        window.location.href = previousURL;
    });

    $('.action-bar .exam-review .actions .next').off().on('click touchend', function(e) {
        e.preventDefault();

        $(this).addClass('active');

        // Get next URL
        var nextURL = $(this).attr('data-next-url');

        // Navigate
        window.location.href = nextURL;
    });

    // Explanation
    $('.action-bar .exam-review .actions .explain').off().on('click touchend', function(e) {
        e.preventDefault();

        // Get the explanation url
        var explainURL = $(this).attr('data-explain-url');

        if (!explainURL) {
            return;
        }

        $(this).colorbox({
            href: explainURL,
            open: true,
            opacity: 0.5,
            onComplete: function() {
                $('.dialog.explanation .dialog-close').off().on('click touchend', function(e) {
                    e.preventDefault();
                    $(this).colorbox.close();
                });

                // find the current Locale
                var currentLocaleCode = $('.toggle-locale.active').find('span').html();
                //console.log('currentLocaleCode = ' + currentLocaleCode);
                if (currentLocaleCode == undefined) {
                    currentLocaleCode = 'en';
                }
                else if (currentLocaleCode == 'ar') {
                    //$('.dialog.explanation .dialog-close').css('left','25px');
                    //$('.dialog.explanation .dialog-title').attr('dir','rtl').attr('lang','ar');
                    //$('.dialog.explanation .answer').attr('dir','rtl').attr('lang','ar');
                    $('.dialog.explanation .explanation').attr('dir','rtl').attr('lang','ar');
                    //$('.dialog.explanation .question-area').attr('dir','rtl').attr('lang','ar');
                    //$('.dialog.explanation .question-group').attr('dir','rtl').attr('lang','ar');
                    //$('.dialog.explanation .question-source-name').attr('dir','rtl').attr('lang','ar');
                    //$('.dialog.explanation .question-source-location').attr('dir','rtl').attr('lang','ar');
                }
            }
        });
    });

    $('.section.review-filters .menu a').off().on('click touchend', function(e) {
        e.preventDefault();

        var modal = $('.section.review-modal');
        var filtersPopup = $('.section.review-filters');
        var reviewNav = $('.action-bar .exam-review .actions');

        filtersPopup.removeClass('active');
        modal.addClass('active');
        reviewNav.hide();

        // Which filter?
        var filterType = $(this).attr('data-filter-type');

        // Filter tiles
        if (!filterType || filterType === 'all') {
            modal.find('.tile').show();
            modal.find('.filters [data-filter-type]').removeClass('active');
            modal.find('.filters [data-filter-type=all]').addClass('active');
        } else {
            modal.find('.tile').hide();
            modal.find('.tile.' + filterType).show();
            modal.find('.filters [data-filter-type]').removeClass('active');
            modal.find('.filters [data-filter-type=' + filterType + ']').addClass('active');
        }
    });

    $('.section.review-modal .filters a').off().on('click touchend', function(e) {
        e.preventDefault();

        var modal = $('.section.review-modal');

        // Which filter?
        var filterType = $(this).attr('data-filter-type');

        // Filter tiles
        if (!filterType || filterType === 'all') {
            modal.find('.tile').show();
            modal.find('.filters [data-filter-type]').removeClass('active');
            modal.find('.filters [data-filter-type=all]').addClass('active');
        } else {
            modal.find('.tile').hide();
            modal.find('.tile.' + filterType).show();
            modal.find('.filters [data-filter-type]').removeClass('active');
            modal.find('.filters [data-filter-type=' + filterType + ']').addClass('active');
        }
    });

    $('.section.review-modal .dialog .closer').off().on('click touchend', function(e) {
        e.preventDefault();

        var modal = $('.section.review-modal');
        var reviewNav = $('.action-bar .exam-review .actions');

        modal.removeClass('active');
        reviewNav.show();
    });

    $('.section.review-modal .dialog .tiles .tile').off().on('click touchend', function(e) {
        e.preventDefault();

        var tileURL = $(this).attr('data-url');

        var activeFilter = $('.section.review-modal .dialog .filters [data-filter-type].active').attr('data-filter-type');

        if (tileURL) {
            if (activeFilter) {
                tileURL = tileURL + '?filter=' + activeFilter;
            }

            window.location.href = tileURL;
        }
    });

    /* Question Note */
    $('.widget.exam-review .actions .toggle-note').off('click touchend').on('click touchend', function(e) {
        e.preventDefault();
        console.log('toggle-note clicked');
        //
        $(this).colorbox({
            open: true,
            opacity: 0.5,
            closeButton:true,
            trapFocus: false,
            inline:true,
            width:"20%",
            href:'#question-note',
            overlayClose:false,
            onComplete: function() {

                $('.dialog-close').off().on('click touchend', function(e) {
                    e.preventDefault();
                    $(this).colorbox.close();
                });
            }
        })

    });

    // Keyboard shortcuts
    if ($('.widget.exam-review').length > 0) {
        $(document).keypress(function(e) {
            if (e.target.tagName.toLowerCase() === 'input') {
                return;
            }

            var code = e.which || e.keyCode;

            if (code === 78 || code === 110) {
                $('.action-bar .exam-review .actions .next').trigger('click');
            } else if (code === 80 || code === 112) {
                $('.action-bar .exam-review .actions .previous').trigger('click');
            }
        });
    }

    // Checkboxes
    $('.widget.exam-review .choices input[type=checkbox]').off('click touchend').on('click touchend', function(e) {
        e.preventDefault();
        e.stopPropagation();
    });

})(jQuery);
