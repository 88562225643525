import Chart from 'chart.js/auto';

(function ($) {

    // Stats overview
    $('.widget.stats-overview .tab').each(function(index, statsOverview) {
        var statsOverview = $(statsOverview);
        $('.section-interior').width('960px');

        var canvas = $(statsOverview.find('.score-history-canvas'));

        if (canvas.length === 0) {
            return;
        }

        var chartCtx = canvas;
        var scores = canvas.attr('data-scores').split(',');

        var chart = new Chart(chartCtx, {
            type: 'line',
            data: {
                labels: ['', 'Exam 1', 'Exam 2', 'Exam 3', 'Exam 4', 'Exam 5', ''],
                datasets: [
                    {
                        data: scores,
                        fill: true,
                        backgroundColor: '#6c9bd3',
                        pointBackgroundColor: '#ffffff',
                        tension: 0
                    }
                ]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                indexAxis: 'x',
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        beginAtZero: true,
                        position: 'bottom',
                        min: 0,
                        max: 100,
                        ticks: {
                            align: 'bottom',
                            crossAlign: 'center',
                            sampleSize: 5,
                            includeBounds: true,
                            stepSize: 10,
                        }
                    }
                }
            }
        });

        var selectors = statsOverview.find('.exam-selectors [data-select]');

        selectors.on('click', function(e) {
            e.preventDefault();

            var selector = $(this);

            var choiceID = selector.attr('data-select');

            selectors.removeClass('active');
            selector.addClass('active');

            statsOverview.find('[data-exam-session-id]').removeClass('active');
            statsOverview.find('[data-exam-session-id=' + choiceID + ']').addClass('active');
        });
    });

    // Detailed stats
    $('.widget.stats-detailed').each(function(index, detailedStats) {
        var detailedStats = $(detailedStats);

        var chartCtx = $('#score-donut');

        var scoreValues = $('#score-donut').attr('data-score-values').split(',');
        var scoreLabels = $('#score-donut').attr('data-score-labels').split(',');

        var chart = new Chart(chartCtx, {
            type: 'doughnut',
            data: {
                labels: scoreLabels,
                datasets: [
                    {
                        data: scoreValues,
                        backgroundColor: ['#67c381', '#ce3537']
                    }
                ]
            },
            options: {
                cutout: 70,
                responsive: false,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                    display: false,
                    },
                    scales: {
                        xAxes: [{ display: false }],
                        yAxes: [{ display: false }]
                    }
                }
            }
        });
    });

})(jQuery);
