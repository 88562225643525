// jQuery
window.$ = window.jQuery = require('jquery');
// bootstrap
require('./bootstrap');

// global.js
require('./global');

// admin.js
require('./admin');

// dialog.js
require('./dialog');

// util.js
require('./util');

// Chart.js
import Chart from 'chart.js/auto';

// widget.js
require('./widget');

// jQuery colorbox
require('jquery-colorbox');

// rangy
//var Rangy = require('rangy');

// canned-launcher
require('./widgets/canned-launcher.js');

// exam-review
require('./widgets/exam-review.js');

// exam-session
require('./widgets/exam-session.js');

// practice-launcher
//require('./widgets/practice-launcher.js');

// study-launcher
require('./widgets/study-launcher.js');

// Setting up CSRF in jQuery
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

(function ($) {

    //$('#exam-timer [data-countdown]').countdown();
    $('#exam-timer [data-countup]').countup();

    $('.panel.tabbed').tabPanel();
    $('.checkboxes').checkboxes();
    //$('.widget.exam .choices').examChoices();

    // Attach the dialog manager to any elements referencing dialogs.
    $('[data-dialog-id]').dialog();

    // Demo Help modal
    $('#help-btn').off().on('click touchend', function(e) {
        e.preventDefault();
        console.log('help button clicked... this = '+$(this));

        $('#help-btn').colorbox({
            open: true,
            opacity: 0.5,
            closeButton:true,
            inline:true,
            width:'fit-content',
            href:'#help_content',
            onComplete: function() {
                $('.dialog-close').off().on('click touchend', function(e) {
                    e.preventDefault();
                    $('#help-btn').colorbox.close();
                });
            }
        })
    });

})(jQuery);
