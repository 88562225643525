const { inArray } = require("jquery");

(function ($) {

    var marking = false;
    var highlighting = false;
    var striking = false;
    var noting = false;
    var resuming = false;
    var highlightApplier;
    var strikeApplier;    

    var markQuestion = function(sessionQuestionID, onSuccess, onFail) {
        //console.log('markQuestion(): marking: ' + marking);
        // Try prevent duplicate marking requests
        if (marking) {
          //console.log('markQuestion(): already marked return');
            return;
        }

        var data = [];

        data.push('_token=' + $.getCSRFToken());
        data.push('mark[session_question_id]=' + sessionQuestionID);

        marking = true;

        // Submit question to mark endpoint
        $.ajax({
            url: '/exam/session/actions/mark',
            data: data.join('&'),
            method: 'POST'
        }).done(function(response) {
            //console.log('markQuestion(): request done:');
            marking = false;
            //console.log('markQuestion(): request done: marking: ' + marking);

            reloadProgressModal();

            if (onSuccess) {
                onSuccess(response);
            }
        }).fail(function(xhr) {
            console.log('markQuestion(): request fail:');
            marking = false;

            // Handle no session error
            if (xhr.status === 401) {
                window.alert('Your session is no longer active. Please sign in again.');
                window.location.reload();
                return;
            }

            // Handle unexpected server error
            if (xhr.status === 500) {
                window.alert('An unexpected server error occurred. Please try again.');
                return;
            }

            var response;

            try {
                response = $.parseJSON(xhr.responseText);
            } catch(e) {
                response = {};
            }

            if (onFail) {
                onFail(xhr.status, response);
            } else {
                window.alert('An unexpected server error occurred. Please try again.');
            }
        });
    };

    window.evaluateNext = function() {
        //console.log('evaluateNext(): window.sac = '+window.sac+' : window.isMarked = '+window.isMarked);
        if ($(".widget.exam.mcm .choices input[type=checkbox]:checked").length < window.sac) {
            $('.needed-choices').text('Additional response(s) required.');
        } else if ($(".widget.exam.mcm .choices input[type=checkbox]:checked").length > window.sac) {
            $('.needed-choices').text('Too many selected.');
        }

        if ($(".widget.exam.mcm .choices input[type=checkbox]:checked").length == window.sac || window.isMarked == true) {
            if ($('.action-bar .exam-session .actions .next').is(':hidden')) {
                $('.action-bar .exam-session .actions .next').toggle();
            }

            if ($('.needed-choices').is(':visible')){
                $('.needed-choices').toggle();
            }
        } else {
            // Turn on message
            if ($('.action-bar .exam-session .actions .next').is(':visible')) {
                $('.action-bar .exam-session .actions .next').toggle();
            }
            if ($('.needed-choices').is(':hidden')){
                $('.needed-choices').toggle();
            }
        }
    };

    window.reloadProgressModal = function() {
        $.ajax({
            url: '/exam/session/progress-modal',
            method: 'GET'
        }).done(function(response) {
            var dialogInterior = $('.section.progress-modal .dialog-interior');
            dialogInterior.empty();
            dialogInterior.html(response);

            setupProgressModal();
        });
    };

    var setupProgressModal = function() {
        $('.section.progress-modal .filters a').off().on('click touchend', function(e) {
            e.preventDefault();

            var modal = $('.section.progress-modal');

            // Which filter?
            var filterType = $(this).attr('data-filter-type');

            // Filter tiles
            if (!filterType || filterType === 'all') {
                modal.find('.tile').show();
                modal.find('.filters [data-filter-type]').removeClass('active');
                modal.find('.filters [data-filter-type=all]').addClass('active');
            } else {
                modal.find('.tile').hide();
                modal.find('.tile.' + filterType).show();
                modal.find('.filters [data-filter-type]').removeClass('active');
                modal.find('.filters [data-filter-type=' + filterType + ']').addClass('active');
            }
        });

        $('.section.progress-modal .dialog .closer').off().on('click touchend', function(e) {
            e.preventDefault();

            var modal = $('.section.progress-modal');
            var examNav = $('.action-bar .exam-session .actions');

            modal.removeClass('active');
            examNav.show();
        });

        $('.section.progress-modal .dialog .tiles .tile').off().on('click touchend', function(e) {
            e.preventDefault();

            var tileURL = $(this).attr('data-url');

            if (tileURL) {
                window.location.href = tileURL;
            }
        });

        $('.section.progress-modal .score-now a.end-review').off().on('click touchend', nextHandler);
    };

    var highlightQuestion = function(sessionQuestionID, onSuccess, onFail) {
        // Try prevent duplicate marking requests
        if (highlighting) {
          return;
        }

        var data = [];

        data.push('_token=' + $.getCSRFToken());

        data.push('highlight[session_question_id]=' + sessionQuestionID);

        highlighting = true;

        // Submit question to mark endpoint
        $.ajax({
            url: '/exam/session/actions/highlight',
            data: data.join('&'),
            method: 'POST'
        }).done(function(response) {
            highlighting = false;

            //reloadProgressModal();

            if (onSuccess) {
                onSuccess(response);
            }
        }).fail(function(xhr) {
            highlighting = false;

            // Handle no session error
            if (xhr.status === 401) {
                window.alert('Your session is no longer active. Please sign in again.');
                window.location.reload();
                return;
            }

            // Handle unexpected server error
            if (xhr.status === 500) {
                window.alert('An unexpected server error occurred. Please try again.');
                return;
            }

            var response;

            try {
                response = $.parseJSON(xhr.responseText);
            } catch(e) {
                response = {};
            }

            if (onFail) {
                onFail(xhr.status, response);
            } else {
                window.alert('An unexpected server error occurred. Please try again.');
            }
        });
    };

    var strikeQuestion = function(sessionQuestionID, onSuccess, onFail) {
        // Try prevent duplicate marking requests
        if (striking) {
          return;
        }

        var data = [];

        data.push('_token=' + $.getCSRFToken());

        data.push('strike[session_question_id]=' + sessionQuestionID);

        striking = true;

        // Submit question to mark endpoint
        $.ajax({
            url: '/exam/session/actions/strike',
            data: data.join('&'),
            method: 'POST'
        }).done(function(response) {
            striking = false;

            //reloadProgressModal();

            if (onSuccess) {
                onSuccess(response);
            }
        }).fail(function(xhr) {
            striking = false;

            // Handle no session error
            if (xhr.status === 401) {
                window.alert('Your session is no longer active. Please sign in again.');
                window.location.reload();
                return;
            }

            // Handle unexpected server error
            if (xhr.status === 500) {
                window.alert('An unexpected server error occurred. Please try again.');
                return;
            }

            var response;

            try {
                response = $.parseJSON(xhr.responseText);
            } catch(e) {
                response = {};
            }

            if (onFail) {
                onFail(xhr.status, response);
            } else {
                window.alert('An unexpected server error occurred. Please try again.');
            }
        });
    };

    var noteQuestion = function(sessionQuestionID, sessionQuestionNote, onSuccess, onFail) {
        // Try prevent duplicate marking requests
        if (noting && sessionQuestionID == null || sessionQuestionID == '') {
          return;
        }

        noting = true;

        var data = [];

        data.push('_token=' + $.getCSRFToken());

        data.push('question[session_question_id]=' + sessionQuestionID);
        data.push('question[note]=' + sessionQuestionNote);
        console.log('toggle-note: data = ' + data);

        // Submit question to note endpoint

        $.ajax({
            url: '/exam/session/actions/note',
            data: data.join('&'),
            method: 'POST'
        }).done(function(response) {
            noting = false;
            console.log('toggle-note: ajax done');

            enableInteractions();
            $('.widget.exam .actions .toggle-note').removeClass('nonote');
            $('.widget.exam .actions .toggle-note').addClass('note');
            $('#question-note').colorbox.close();

            if ($('#hasNote').is(':hidden')) {
                console.log('hasNote is visible...toggle');
                $('#hasNote').toggle();
            }

            if (onSuccess) {
                onSuccess(response);
            }
        }).fail(function(xhr) {
            noting = false;

            // Handle no session error
            if (xhr.status === 401) {
                window.alert('Your session is no longer active. Please sign in again.');
                window.location.reload();
                return;
            }

            // Handle unexpected server error
            if (xhr.status === 500) {
                window.alert('An unexpected server error occurred. Please try again.');
                return;
            }

            var response;

            try {
                response = $.parseJSON(xhr.responseText);
            } catch(e) {
                response = {};
            }

            if (onFail) {
                onFail(xhr.status, response);
            } else {
                window.alert('An unexpected server error occurred. Please try again.');
            }
        });
    };

    var selectText = function(el, doWhat) {

        //console.log('selectText: ' + el + ' : ' + doWhat);

        var originalHtml = $(el).html().trim();
        //console.log('originalHtml: ' + originalHtml);
        // find the current Locale
        var l = $('.toggle-locale.active').find('span').html();
        //console.log('l = ' + l);
        if (l == undefined) {
            l = 'en';
        }
        //console.log('l = ' + l);

        var dsq_id = $(el).parent('.question').data('session-question-id');
        //console.log('dsq_id: ' + dsq_id);
        var dsc_id = $(el).parents('.choice').data('session-choice-id');
        //console.log('dsc_id: ' + dsc_id);

        changeHtmlSelection(doWhat);

        var newHtml = $(el).html().trim();
        //console.log('newHtml: ' + newHtml);

        // Store to sessionStorage
        if(dsq_id) {
            //console.log('dsq_id from session = ' + sessionStorage.getItem(dsq_id));
            // append Locale to end.
            dsq_id += '_' + l;
            //console.log('dsq_id: ' + dsq_id);
            //check if HTML is different than stored value
            if (sessionStorage.getItem(dsq_id) != newHtml && newHtml != originalHtml) {
                sessionStorage.setItem('q' + dsq_id, newHtml);
                //console.log("Save new question HTML to session");
            }
        }

        if(dsc_id) {
            //console.log('dsc_id from session = ' + sessionStorage.getItem(dsc_id));
            // append Locale to endif
            dsc_id += '_' + l;
            //console.log('dsc_id: ' + dsc_id);
            //check if HTML is different than stored value
            if (sessionStorage.getItem(dsc_id) != newHtml && newHtml != originalHtml) {
                sessionStorage.setItem('c' + dsc_id, newHtml);
                //console.log("Save new choice HTML to session");
            }
        }
    }

    function changeHtmlSelection(doWhat) {
        //console.log('changeHtmlSelection( ' + doWhat + ')');
        if(doWhat==''){
            return;
        }

        if (rangy) {
            var sel, range, node;

            sel = rangy.getSelection();
            //console.log('changeHtmlSelection: sel = ' + sel);
            if (sel.getRangeAt && sel.rangeCount) {

                for (var i = 0; i < sel.rangeCount; i++) {
                    var range = sel.getRangeAt(i);
                    //console.log('range = ' + range);
                    var $sNode = $(sel.getRangeAt(i).startContainer.parentNode);
                    //console.log('$sNode = ' + $sNode);
                    var $eNode = $(sel.getRangeAt(i).endContainer.parentNode);
                    //console.log('$eNode = ' + $eNode);

                    var nodeAncestor = range.commonAncestorContainer;
                    //console.log('nodeAncestor = ' + nodeAncestor);
                    //console.log('nodeAncestor.className = ' + nodeAncestor.className);

                    if($sNode.parent().data('session-choice-id') == $eNode.parent().data('session-choice-id')) {
                        //console.log('Parent Matched');
                        if(doWhat == 'highlight') {
                            toggleHighlight();
                        }
                        else if(doWhat == 'strikethrough') {
                            toggleStrikethrough();
                        }
                    }
                    else if (nodeAncestor.className == 'text' || nodeAncestor.className == 'content') {
                        //console.log('Ancestor Matched');
                        if(doWhat == 'highlight') {
                            toggleHighlight();
                        }
                        else if(doWhat == 'strikethrough') {
                            toggleStrikethrough();
                        }
                    }
                    else {
                        //console.log('Selecting from multiple elements');
                        alert('Selection must be within the question or the same choice.');
                    }
                }
            }
        }
        window.getSelection().removeAllRanges();
    }


    // Get the original font size in the css so it can multiplied by the factor after being updated
    function setOriginalFontSize() {
        var fontElements = [
            $(".widget.exam .question .content"),
            $(".widget.exam .question .choices .choice .human-id"),
            $(".widget.exam .question .choices .choice .text"),
            $(".widget.exam .question .choices .dnd_choice .text")
        ];

        $.each(fontElements, function(index, element){
            var fontSize = parseInt(element.css("font-size"));
            element.data('font-size',fontSize);
            var lineHeight = parseInt(element.css("line-height"));
            element.data('line-height',lineHeight);
        });

    }
    setOriginalFontSize();

    // Get the current font-factor attribute and update the font sizes accordingly
    function setPresentationFontSize() {
        var fontFactor = $('body').data('font-factor');

        if(typeof fontFactor === "undefined" || fontFactor == "") {
            fontFactor = 1;
        }

        // Array of the elements for which font size and line height should be updated
        var fontElements = [
            $(".widget.exam .question .content"),
            $(".widget.exam .question .choices .choice .human-id"),
            $(".widget.exam .question .choices .choice .text")
        ];

        $.each(fontElements, function(index, element) {
            var fontSize = element.data('font-size');
            fontSize = (fontSize * fontFactor) + "px";

            var lineHeight = element.data('line-height');
            lineHeight = (lineHeight * fontFactor) + "px";

            element.css({'line-height':lineHeight, 'font-size':fontSize});
        });
    }
    // The font-factor is stored in the session to set the body data attribute
    setPresentationFontSize();

    var continue_exam = function() {
        if (resuming) {
          return;
        }
        resuming = true;

        clearTimeout(btTimeout);
        //window.location.replace('/exam/session/actions/resume');
        var data = [];
        data.push('_token=' + $.getCSRFToken());

        $.ajax({
            url: '/exam/session/actions/resume',
            data: data.join('&'),
            method: 'GET'
        }).done(function(response) {
            console.log('Resume done: ' + response);
            resuming = false;
            $.colorbox.close();
            window.breakDeadline = 0;
            window.timer.resume();
            window.location.replace('/exam/session/'+response);
        }).fail(function(xhr) {
            resuming = false;
            console.log('Resume failed.');
        });
    }

    function displayBreakTimer(nextURL) {
        // display the break timer in a colorbox based on the nextURL
        $.colorbox({
            href: nextURL,
            returnFocus: true,
            open: true,
            opacity: 0.5,
            escKey: false,
            overlayClose: false,
            onComplete: function() {
                //console.log('onComplete ');
                window.history.pushState(null, "", window.location.href);
                window.history.back();
                window.history.forward();
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
              };

                if(typeof (global) === "undefined") {
                    throw new Error("window is undefined");
                }

                var _hash = "!";
                var noBackPlease = function () {
                    global.location.href += "#";

                    // making sure we have the fruit available for juice (^__^)
                    global.setTimeout(function () {
                        global.location.href += "!";
                    }, 50);
                };

                global.onhashchange = function () {
                    if (global.location.hash !== _hash) {
                        global.location.hash = _hash;
                    }
                };

                global.onload = function () {
                    noBackPlease();

                    // disables backspace on page except on input fields and textarea..
                    document.body.onkeydown = function (e) {
                        var elm = e.target.nodeName.toLowerCase();
                        if (e.which === 8 && (elm !== 'input' && elm  !== 'textarea')) {
                            e.preventDefault();
                        }
                        // stopping event bubbling up the DOM tree..
                        e.stopPropagation();
                    };
                }

                $.fn.br_countdown = function(settings) {
                    //console.log('br_countdown = ' + settings);
                    settings = $.extend({}, settings);

                    var ele = $(this);
                    //console.log('exam-session:br_countdown: ele = '+ele);
                    var countdown = parseInt(ele.attr('data-countdown'));
                    //console.log('exam-session:br_countdown: countdown = '+countdown);

                    //var deadline = new Date();
                    // make sure this only sets the breakDeadline once.
                    if (window.breakTaken == false) {
                        window.breakTaken = true;
                        window.breakDeadline = new Date();
                        window.breakDeadline.setSeconds(window.breakDeadline.getSeconds() + countdown);
                    }

                    //var stopwatch;
                    var breaktimer;

                    var timeLeft = function(end) {
                        var delta = end - new Date();

                        var seconds = Math.floor((delta / 1000) % 60);
                        //var minutes = Math.floor((delta / 1000 / 60) % 60);
                        var minutes = Math.floor(delta / 1000 / 60);
                        var hours = Math.floor((delta / (1000 * 60 * 60)) % 24);
                        var days = Math.floor(delta / (1000 * 60 * 60 * 24));

                        return {
                            'total': delta,
                            'days': days,
                            'hours': hours,
                            'minutes': minutes,
                            'seconds': seconds
                        };
                    }

                    var pad = function(num, size) {
                        var s = num + '';
                        while (s.length < size) s = '0' + s;
                        return s;
                    }

                    var bt_tick = function() {
                        //console.log('exam-session:br_countdown: bt_tick');
                        // console.log('exam-session:br_countdown: bt_tick(): window.breakDeadline = '+window.breakDeadline);
                        var tl = timeLeft(window.breakDeadline);
                        console.log('exam-session:br_countdown: bt_tick(): tl = '+tl);

                        if (tl.total > 0) {
                            btTimeout = setTimeout(bt_tick, 900);
                        }
                        else {
                            continue_exam();
                        }
                        
                        ele.text(pad(tl.minutes, 2) + ':' + pad(tl.seconds, 2));
                        //window.dl.setSeconds(dl.getSeconds());
                        //console.log('exam-session:br_countdown: bt_tick(): window.dl = '+window.dl);
                        // console.log('exam-session:br_countdown: bt_tick(): window.dl.total = '+window.dl.total);
                        //window.dl = timeLeft(window.dl).total - timeLeft(deadline).total;
                        //console.log('exam-session:br_countdown: bt_tick(): window.dl = '+window.dl);
                        //console.log('exam-session:br_countdown: bt_tick(): window.dl.total = '+window.dl.total);
                        //deadline.setSeconds(deadline.getSeconds() + countdown);

                        
                    };

                    bt_tick();

                    window.timer.pause();
                };

                $('#break-dialog [data-countdown]').br_countdown();

                $('.dialog-close').off().on('click touchend', function(e) {
                    e.preventDefault();
                    continue_exam();
                });
            }
        });
    }

    var nextHandler = function(e) {
        e.preventDefault();
        console.log('nextHandler');
        $(this).addClass('active');

        // Get next URL
        var nextURL = $(this).attr('data-next-url');
        console.log('nextURL = '+nextURL);
        // check if coming from Next button or End Review button
        if ( $(this).hasClass('end-review') ){
            console.log('end-review button clicked');
            nextURL = '/exam/session/break-modal';
        }

        navigationHandler(nextURL);
    };

    function navigationHandler(nextURL) {
        console.log('navigationHandler() nextURL = '+nextURL);
        var onSuccess = function(response) {
            //console.log('onSuccess() response = '+response);
            console.log('onSuccess() nextURL = '+nextURL);

            if (nextURL == "/exam/session/break-modal"){
                if (window.breakTriggered == false) {
                    window.breakTriggered = true;
                }
                $(this).colorbox({
                    href: nextURL,
                    returnFocus: true,
                    open: true,
                    opacity: 0.5,
                    onComplete: function() {
                        $('.dialog.break .dialog-close').off().on('click', function(e) {
                            e.preventDefault();
                            $(this).colorbox.close();
                        });

                        $('form[id="discard"]').off().on('click touchend', function(e) {
                            var r = confirm('Exam In Progress, you will lose all exam data, Are you sure?');
                            if (r==true){
                                return;
                            } else {
                                e.preventDefault();
                            }
                        })

                        // Sumbit Part 1
                        $('#break-dialog .actions .submit').off().on('click touchend', function(e) {
                            e.preventDefault();
                            console.log('submit button clicked');
                            $(this).addClass('active');

                            // Get next URL
                            var nextURL = $(this).attr('href');
                            console.log('nextURL = '+nextURL);

                            if (nextURL == "/exam/session/actions/break"){
                                // display break timer screen in colorbox
                                displayBreakTimer(nextURL);
                            }
                            else if (nextURL == "/exam/session/actions/resume") {
                                e.preventDefault();
                                continue_exam();
                            }
                            else if (nextURL == "/exam/session") {
                                console.log('back button clicked');
                                $.colorbox.close();
                                //$('.action-bar .exam-session .review a').trigger("click");
                                $('.section.progress-filters .menu a').trigger("click");
                            }
                        });

                        if (window.breakTriggered == 1 && window.breakRunning == 1 && window.breakTaken == false){
                            console.log('has breakDeadline... trigger Submit click');
                            nextURL = '/exam/session/actions/break';
                            displayBreakTimer(nextURL);
                        }
                    }
                });
            }
            else {
                window.location.replace(nextURL);
            }
        };

        var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
        
        if ((examQuestionTypeID == 0 || examQuestionTypeID == 1 || examQuestionTypeID == 7) &&
             $('.widget.exam.mcs .choices .choice.selected').attr('data-session-choice-id')) {// MCS or Comic
            // Grab selected answer
            var selectedChoice = $('.widget.exam .choices .choice.selected');
            var sessionChoiceID = selectedChoice.attr('data-session-choice-id');
            console.log('MCS sessionChoiceID = '+sessionChoiceID);
            // console.log('MCS typeof(sessionChoiceID) = '+typeof(sessionChoiceID));
            console.log('MCS sessionAnswerID = '+sessionAnswerID);
            // console.log('MCS typeof(sessionAnswerID) = '+typeof(sessionAnswerID));
            if (sessionAnswerID != '' && sessionChoiceID != sessionAnswerID) {
                console.log('MCS Answer is different... answerMCSQuestion()')
                answerMCSQuestion(sessionQuestionID, sessionChoiceID, onSuccess);
            } else {
                onSuccess();
            }

        } else if (examQuestionTypeID == 2) {// DND
            // do nothing as question should already be answered
            var dnd_answered = false;
            var selectedChoices = [];
            var all_zones = document.querySelectorAll('.choices .zone');
            all_zones.forEach(function(zone) {
                if (zone.getAttribute('data-session-choice-id') != null) {
                    dnd_answered = true;
                }
                // Answer data
                selectedChoices.push({zone:zone.id, dnd_choice:zone.getAttribute('data-session-choice-id')});
            });
            if (selectedChoices.length > 0 && dnd_answered){
                console.log('selectedChoices[0].zone = ' + selectedChoices[0].zone);
                answerdndQuestion(sessionQuestionID, selectedChoices, onSuccess);
            } else {
                onSuccess();
            }
        } else if (examQuestionTypeID == 4) {// HS
            onSuccess();
        } else if (examQuestionTypeID == 5 && $(".widget.exam.mcm .choices input[type=checkbox]:checked")) {// MCM
            var mcm_answered = false;
            var selectedChoices = [];
            $.each($(".widget.exam.mcm .choices input[type=checkbox]:checked"), function(){
                selectedChoices.push($(this).val());
                mcm_answered = true;
            });

            if (selectedChoices.length > 0 && mcm_answered){
                answerMCMQuestion(sessionQuestionID, selectedChoices, onSuccess);
            } else {
                onSuccess();
            }
        } else if (examQuestionTypeID == 6 && $('.widget.exam.fill-blank .form-control.user-answer').val()) {// FB
            
            var textAnswer = encodeURIComponent($('.widget.exam.fill-blank .form-control.user-answer').val().toUpperCase());
            if (textAnswer != sessionAnswer) {
                answerFillBlankQuestion(sessionQuestionID, textAnswer, onSuccess);
            } else {
                onSuccess();
            }
        }
        else {
            onSuccess();
        }
    }


    $('.progress .presentation .font-size.up').off().on('click touchend', function(e) {
        var fontFactor = $('body').data('font-factor');
        fontFactor = fontFactor + 0.1;
        $('body').data('font-factor', fontFactor);

        setPresentationFontSize();
        updateFontFactor(fontFactor);
    });

    $('.progress .presentation .font-size.down').off().on('click touchend', function(e) {
        var fontFactor = $('body').data('font-factor');
        fontFactor = fontFactor - 0.1;
        $('body').data('font-factor', fontFactor);

        setPresentationFontSize();
        updateFontFactor(fontFactor);
    });

    function updateFontFactor(fontFactor) {
        var data = [];
        data.push('_token=' + $.getCSRFToken());
        data.push('font_factor=' + fontFactor);

        $.ajax({
            url: '/exam/session/font-factor',
            data: data.join('&'),
            method: 'POST'
        }).done(function(response) {
            //console.log(response);
        }).fail(function(xhr) {
            //console.log('Font factor update failed.');
        });
    }

    function enableInteractions() {
        console.log("enableInteractions()");

        $('.header-layout .left a').on('click touchend', function(e) {
            //console.log("enableInteractions(): logo clicked");
            e.preventDefault();
        });
        $('.header-layout .right a.account').on('click touchend', function(e) {
            //console.log("enableInteractions(): account settings clicked");
            e.preventDefault();
            alert('Unable to show Account Settings while taking an exam');
        });
        $('.header-layout .right a.confirm').on('click touchend', function(e) {
            //console.log("enableInteractions(): sign out clicked");
            //e.preventDefault();
            return confirm('Exam In Progress, Are you sure?');
        });
        $('.widget.exam .content').off('click touchend');
        $('.widget.exam .choice .text').off('click touchend');
        $('.widget.exam .choices').examChoices();

        // multiple_choice_single
        $('.widget.exam.mcs .choices .choice').off('click.userAnswer').on('click.userAnswer', function(e) {
            e.preventDefault();
            // Grab selected answer
            if(e.handled !== true) // This will prevent event triggering more then once
            {
                //console.log("exam-session: mcs Choice - clicked");
                event.handled = true;
            }
            //console.log("exam-session: mcs Choice - clicked");

            var selectedChoice = $(this);

            var unanswering = selectedChoice.hasClass('selected');

            var sessionChoiceID = selectedChoice.attr('data-session-choice-id');
            var sessionQuestionID = $('.exam .question').attr('data-session-question-id');

            if (!unanswering) {
                sessionChoiceID = null;
            }

            answerMCSQuestion(sessionQuestionID, sessionChoiceID);
        });

        // multiple_choice_multiple
        $('.widget.exam.mcm .choices .choice input[type=checkbox]').off('click.userAnswer').on('click.userAnswer', function(e) {
            //e.preventDefault();// commented so the checkbox will checked
            // Grab selected answer
            if(e.handled !== true) // This will prevent event triggering more then once
            {
                //console.log("exam-session: mcm Choice - clicked: "+e.target);
                e.handled = true;
            }
            //e.preventDefault();
            var selectedChoices = [];
            var selectedChoice = $(this);

            $.each($(".widget.exam.mcm .choices input[type=checkbox]:checked"), function(){
                selectedChoices.push($(this).val());
            });
            //console.log("exam-session: selectedChoices = " + selectedChoices.join(", "));
            console.log("exam-session: selectedChoices = " + selectedChoices);

            var sessionQuestionID = $('.exam .question').attr('data-session-question-id');

            answerMCMQuestion(sessionQuestionID, selectedChoices);
        });

        // Fill Blank
        $('.widget.exam.fill-blank .form-control.user-answer').off().on('keyup', function(e) {
            e.preventDefault
            var curAns = sessionAnswer ?? '';
            var key = e.key || e.keyCode;
            console.log('fill-blank: Answer = '+ key);
            // Acceptable Keys
            $allowedKeysArr = ['a',65,'A',97,'b',66,'B',98,'c',67,'C',99,'d',68,'D',100,'Backspace'];
            console.log('fill-blank: $allowedKeysArr.includes(key) = '+ $allowedKeysArr.includes(key));
            if ($allowedKeysArr.includes(key)) {
                // Grab selected answer
                //console.log("fill-blank: Answer - changed");
                //e.preventDefault();
                $(this).val().toUpperCase().trim();
                var val = String($(this).val());
                console.log('fill-blank: val = '+ val);
                console.log('fill-blank: val.length = '+ val.length);
                if (val.length == 0) {
                    val = 'cleared';
                }
                if (val != null && val != curAns) {
                    var textAnswer = val;
                    curAns = val;
                    var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
                    //console.log("fill-blank: Answer Submitting");
                    answerFillBlankQuestion(sessionQuestionID, textAnswer);
                }
            } else {
                $(this).val(curAns);
                $(this).trigger('select');
            }
        });

        $('.action-bar .exam-session .review a').off().on('click touchend', function(e) {
            e.preventDefault();
            //console.log("enableInteractions(): review - clicked");
            var modal = $('.section.progress-modal');
            var filtersPopup = $('.section.progress-filters');
            var examNav = $('.action-bar .exam-session .actions');

            if (modal.hasClass('active')) {
                modal.removeClass('active');
                examNav.show();
            } else if (filtersPopup.hasClass('active')) {
                filtersPopup.removeClass('active');
                examNav.show();
            } else {
                filtersPopup.addClass('active');
            }
        });

        $('.action-bar .exam-session .actions .previous').off().on('click touchend', function(e) {
            e.preventDefault();

            $(this).addClass('active');

            // Get previous URL
            var previousURL = $(this).attr('data-previous-url');

            // Navigate
            navigationHandler(previousURL);
            // window.location.href = previousURL;
        });

        $('.action-bar .exam-session .actions .next').off().on('click touchend', nextHandler);

        // Progress filter menu
        $('.section.progress-filters .menu a').off().on('click touchend', function(e) {
            e.preventDefault();

            var modal = $('.section.progress-modal');
            var filtersPopup = $('.section.progress-filters');
            var examNav = $('.action-bar .exam-session .actions');

            filtersPopup.removeClass('active');
            modal.addClass('active');
            examNav.hide();

            // Which filter?
            var filterType = $(this).attr('data-filter-type');

            // Filter tiles
            if (!filterType || filterType === 'all') {
                modal.find('.tile').show();
                modal.find('.filters [data-filter-type]').removeClass('active');
                modal.find('.filters [data-filter-type=all]').addClass('active');
            } else {
                modal.find('.tile').hide();
                modal.find('.tile.' + filterType).show();
                modal.find('.filters [data-filter-type]').removeClass('active');
                modal.find('.filters [data-filter-type=' + filterType + ']').addClass('active');
            }
        });

        // Peek at answer
        $('.widget.exam .actions .peek-answer').off().on('click touchend', function(e) {
            e.preventDefault();

            // Get the question position
            var questionPosition = $(this).attr('data-session-question-position');

            if (!questionPosition) {
                return;
            }

            $(this).colorbox({
                href: '/exam/session/actions/peek/' + questionPosition,
                open: true,
                opacity: 0.5,
                onComplete: function() {
                    $('.dialog.peek .dialog-close').off().on('click touchend', function(e) {
                        e.preventDefault();
                        $(this).colorbox.close();
                    });

                    // find the current Locale
                    var currentLocaleCode = $('.toggle-locale.active').find('span').html();
                    //console.log('currentLocaleCode = ' + currentLocaleCode);
                    if (currentLocaleCode == undefined) {
                        currentLocaleCode = 'en';
                    }
                    else if (currentLocaleCode == 'ar') {
                        //$('.dialog.peek .dialog-close').css('left','25px');
                        //$('.dialog.peek .dialog-title').attr('dir','rtl').attr('lang','ar');
                        //$('.dialog.peek .answer').attr('dir','rtl').attr('lang','ar');
                        $('.dialog.peek .explanation').attr('dir','rtl').attr('lang','ar');
                        //$('.dialog.peek .question-area').attr('dir','rtl').attr('lang','ar');
                        //$('.dialog.peek .question-group').attr('dir','rtl').attr('lang','ar');
                        //$('.dialog.peek .question-source-name').attr('dir','rtl').attr('lang','ar');
                        //$('.dialog.peek .question-source-location').attr('dir','rtl').attr('lang','ar');
                    }
                }
            });
        });

        // Mark question
        $('.widget.exam .actions .toggle-mark').off().on('click touchend', function(e) {
            e.preventDefault();
            //console.log('toggle-mark');

            var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
            //console.log('toggle-mark: sessionQuestionID: ' + sessionQuestionID);

            //console.log('toggle-mark: response: ' + response);
            var onMarkSuccess = function(response) {
                //console.log('toggle-mark: onSuccess: ' + onSuccess);
                //console.log('toggle-mark: response: ' + response);
                if (response.marked) {
                    //console.log('toggle-mark: addClass');
                    $('.widget.exam .actions .toggle-mark').addClass('marked');
                } else {
                    //console.log('toggle-mark: removeClass');
                    $('.widget.exam .actions .toggle-mark').removeClass('marked');
                }
                
                if (window.sac != null) {
                    window.isMarked = $('.widget.exam .actions .toggle-mark').hasClass('marked');
                    evaluateNext();
                }
            };

            markQuestion(sessionQuestionID, onMarkSuccess);
        });

        $('.widget.exam .actions .toggle-highlight').off('click touchend').on('click touchend', function(e) {
            e.preventDefault();
            //console.log('toggle-highlight clicked');

            $('.widget.exam .actions .toggle-highlight').toggleClass('highlighted');
            $('.widget.exam .content').toggleClass('noselect');
            $('.widget.exam .choices .choice .text').toggleClass('noselect');

            if ($('.widget.exam .actions .toggle-highlight.highlighted').length >0) {
                //console.log("toggle-highlight clicked : has highlighted enabled");

                disableInteractions();

                $('.widget.exam .actions .toggle-strike').off('click touchend');

                $('.widget.exam .content').off('click touchend').on('click touchend',
                    function(e) {
                        e.preventDefault();
                        //console.log('toggle-highlight clicked : highlight content clicked');

                        var el = this;
                        selectText(el, 'highlight');

                        if ($('.widget.exam .actions .toggle-highlight.highlighted').length >0) {
                            //console.log("has highlighted enabled");
                            // Toggle back to exam mode.
                            $('.widget.exam .actions .toggle-highlight').toggleClass('highlighted');
                            $('.widget.exam .content').toggleClass('noselect');
                            $('.widget.exam .choices .choice .text').toggleClass('noselect');
                            e.stopPropagation();
                            enableInteractions();
                        }
                    }
                );

                $('.widget.exam .choices .choice').off('click.userAnswer');
                $('.widget.exam .choices .choice .text').off('click touchend').on('click touchend',
                    function(e) {
                        e.preventDefault();
                        //console.log('toggle-highlight clicked : highlight choice clicked');

                        var el = this;
                        selectText(el, 'highlight');

                        if ($('.widget.exam .actions .toggle-highlight.highlighted').length >0) {
                            //console.log("has highlighted enabled");
                            // Toggle back to exam mode.
                            $('.widget.exam .actions .toggle-highlight').toggleClass('highlighted');
                            $('.widget.exam .content').toggleClass('noselect');
                            $('.widget.exam .choices .choice .text').toggleClass('noselect');
                            e.stopPropagation();
                            enableInteractions();
                        }
                    }
                );
            }
            else {
                //console.log("highlighted disabled");
                e.stopPropagation();
                enableInteractions();
            }

            // The code below can be used to write the toggle value to the database.
            /*
            var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
            //console.log('toggle-highlight: sessionQuestionID: ' + sessionQuestionID);

            //console.log('response: ' + response);

            var onHighlightSuccess = function(response) {
                //console.log('onSuccess: ' + onSuccess);
                if (response.highlighted) {
                    //console.log('toggle-highlight: addClass');
                    $('.widget.exam .actions .toggle-highlight').addClass('highlighted');
                } else {
                    //console.log('toggle-highlight: removeClass');
                    $('.widget.exam .actions .toggle-highlight').removeClass('highlighted');
                }
            };

            highlightQuestion(sessionQuestionID, onHighlightSuccess);
            */
        });

        // Strike question text
        $('.widget.exam .actions .toggle-strike').off('click touchend').on('click touchend', function(e) {
            e.preventDefault();
            //console.log('toggle-strike');

            $('.widget.exam .actions .toggle-strike').toggleClass('striked');
            $('.widget.exam .content').toggleClass('noselect');
            $('.widget.exam .choice .text').toggleClass('noselect');

            if ($('.widget.exam .actions .toggle-strike.striked').length >0) {
                //console.log("has strikethrough enabled");

                disableInteractions();

                $('.widget.exam .actions .toggle-highlight').off('click touchend');

                $('.widget.exam .content').off('click touchend').on('click touchend',
                    function(e) {
                        e.preventDefault();
                        //console.log('toggle-strike clicked : Strikethrough content clicked');

                        var el = this;
                        selectText(el, 'strikethrough');

                        if ($('.widget.exam .actions .toggle-strike.striked').length >0) {
                            // Toggle back to exam mode
                            $('.widget.exam .actions .toggle-strike').toggleClass('striked');
                            $('.widget.exam .content').toggleClass('noselect');
                            $('.widget.exam .choices .choice .text').toggleClass('noselect');
                            e.stopPropagation();
                            enableInteractions();
                        }
                    }
                );

                $('.widget.exam .choices .choice .text').off('click touchend').on('click touchend',
                    function(e) {
                        e.preventDefault();
                        //console.log('strikethrough choice');

                        var el = this;
                        selectText(el, 'strikethrough');

                        if ($('.widget.exam .actions .toggle-strike.striked').length >0) {
                            // Toggle back to exam mode
                            $('.widget.exam .actions .toggle-strike').toggleClass('striked');
                            $('.widget.exam .content').toggleClass('noselect');
                            $('.widget.exam .choices .choice .text').toggleClass('noselect');
                            e.stopPropagation();
                            enableInteractions();
                        }
                    }
                );
            }
            else {
                //console.log("striked disabled");
                e.stopPropagation();
                enableInteractions();
            }

            // The code below can be used to write the toggle value to the database.
            /*
            var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
            console.log('toggle-strike: sessionQuestionID: ' + sessionQuestionID);

            var onStrikeSuccess = function(response) {
                if (response.striked) {
                    console.log('toggle-strike: addClass');
                    $('.widget.exam .actions .toggle-strike').addClass('striked');
                } else {
                    console.log('toggle-strike: removeClass');
                    $('.widget.exam .actions .toggle-strike').removeClass('striked');
                }
            };

            strikeQuestion(sessionQuestionID, onStrikeSuccess);
            */
        });
    }

    /* Question Note */
    $('.widget.exam .actions .toggle-note').off('click touchend').on('click touchend', function(e) {
        e.preventDefault();
        console.log('toggle-note clicked');

        disableInteractions();
        //
        $(this).colorbox({
            open: true,
            opacity: 0.5,
            closeButton:true,
            trapFocus: false,
            inline:true,
            width:"20%",
            href:'#question-note',
            overlayClose:false,
            onComplete: function() {
                $('form input:first').focus();

                $('.dialog-close').off().on('click touchend', function(e) {
                    e.preventDefault();
                    $(this).colorbox.close();
                    enableInteractions();
                });

                $('#submitBtn').off().on('click touchend', function(e) {
                    e.preventDefault();
                    console.log("colorbox: submit attempted");

                    var sessionQuestionID = $('.exam .question').attr('data-session-question-id');
                    console.log('toggle-note: sessionQuestionID: ' + sessionQuestionID);
                    var sessionQuestionNote = encodeURIComponent($('#question_note').val());
                    console.log('toggle-note: sessionQuestionNote: ' + sessionQuestionNote);

                    if (!sessionQuestionID && sessionQuestionNote.length >0) {
                        return;
                    }

                    noteQuestion(sessionQuestionID, sessionQuestionNote);
                });
            }
        })

    });

    function disableInteractions() {
        console.log("disableInteractions()");
        $('#rmc-logo-link').on('click touchend');
        $('.widget.exam .choices .choice').off('click.checkRadio');
        $('.widget.exam .choices .choice').off('click.userAnswer');
        $('.widget.exam .choices .choice').off('choice:select');
        $('.action-bar .exam-session .review a').off('click');
        $('.section.progress-filters .menu a').off('click');
        $('.action-bar .exam-session .actions .previous').off('click');
        $('.action-bar .exam-session .actions .next').off('click');
        //$('.widget.exam .actions .toggle-locale').off('click');
        $('.widget.exam .actions .peek-answer').off('click');
        $('.widget.exam .actions .toggle-mark').off('click');
    }

    function toggleHighlight() {
        highlightApplier.toggleSelection();
    }

    function toggleStrikethrough() {
        strikeApplier.toggleSelection();
    }


    $(function() {
        rangy.init();

        highlightApplier = rangy.createClassApplier("highlight", true);
        strikeApplier = rangy.createClassApplier("strikethrough", true);

        // find the current Locale
        var currentLocaleCode = $('.toggle-locale.active').find('span').html();
        //console.log('currentLocaleCode = ' + currentLocaleCode);
        if (currentLocaleCode == undefined) {
            currentLocaleCode = 'en';
        }
        else if (currentLocaleCode == 'ar') {
            //$('.widget.exam .content').attr('dir','rtl').attr('lang','ar');
            //$('.widget.exam .choice').attr('dir','rtl').attr('lang','ar');
            //$('.widget.exam-review .content').attr('dir','rtl').attr('lang','ar');
            //$('.widget.exam-review .choices').css('display','unset');
            //$('.widget.exam-review .choice').attr('dir','rtl').attr('lang','ar');
            //$('.widget.exam-review .choices .selection-marker').attr('dir','rtl').attr('lang','ar');
            //$('.widget.exam-review .choices .selection-marker').css('display','inline-block').css('position','relative').css('left','0').css('visibility','hidden').css('background','url(/images/selection-marker-l.png) no-repeat top left');
            //$('.widget.exam-review .choices .selection-marker.selected').css('visibility','visible').css('top','2px');
        }
        //console.log('currentLocaleCode = ' + currentLocaleCode);

        // check if page is an exam page
        //console.log(document.location.pathname.indexOf("/session/"));
        if (document.location.pathname.indexOf("/session/") === 5) {
            var currentExamSessionID = examSessionID;
            //console.log('currentExamSessionID = ' + currentExamSessionID);

            enableInteractions();
            // Check SessionStorage for stored values
            if (typeof(Storage) !== "undefined") {
                //console.log("Code for localStorage/sessionStorage.");
                //console.log("sessionStorage = " + sessionStorage);
                // check that we are using the current session ID
                // Get saved data from sessionStorage
                var ceID = sessionStorage.getItem('currentExamSessionID');
                //console.log("ceID = " + ceID);

                if(ceID == currentExamSessionID){
                    //console.log('Existing Session');

                    for(var key in sessionStorage){
                        if(sessionStorage.hasOwnProperty(key)) {
                            //console.log('key = ' + key + ' : value = ' + sessionStorage[key]);

                            // check if it is the question
                            var q = $(this).find('.question');
                            //console.log('q = ' + q);
                            var qID = q.data('session-question-id');
                            //console.log('qID = ' + qID);
                            if(key == 'q' + qID + '_' + currentLocaleCode) {
                                // key is question
                                q.find('.content').html(sessionStorage[key]);
                                //console.log('Replacing question HTML from session');
                                //q.innerHTML = sessionStorage[key];
                            }

                            //console.log('Answer chosen = ' + $('.widget.exam .choices .choice').data('session-choice-id') );
                            $('.widget.exam .choices .choice').each(function( index ) {
                                var choiceID = $( this ).data('session-choice-id');
                                //console.log( index + ": " + choiceID );
                                if(key == 'c' + choiceID + '_' + currentLocaleCode) {
                                    $( this ).find('.text').html(sessionStorage[key]);
                                    //console.log('Replacing choice HTML from session');

                                }
                            });
                            //console.log( $('.widget.exam .choices .choice').data('session-choice-id') );
                        }
                    }
                }
                else {
                    console.log('New Session... set to sessionStorage: currentExamSessionID = '+currentExamSessionID);
                    sessionStorage.clear();
                    sessionStorage.setItem('currentExamSessionID', currentExamSessionID);
                }
            } else {
                //console.log("Sorry! No Web Storage support..");
                //disable highlight and strikethrough
                $('.widget.exam .actions .toggle-highlight').visible = false;
                $('.widget.exam .actions .toggle-strike').visible = false;
            }
        }
    });


    if ($('.widget.exam .actions .toggle-strike.striked').length >0) {
        //console.log("has striked enabled");
        $('.widget.exam .content').on('click touchend',
            function(e) {
                e.preventDefault();
                //console.log('strike content');
                var el = this;
                selectText(el, 'strike');
            }
        );

        $('.widget.exam .choice .text').on('click touchend',
            function(e) {
                e.preventDefault();
                //console.log('strike choice');
                var el = this;
                selectText(el, 'strike');
            }
        );
    }

    // Keyboard shortcuts
    if ($('.widget.exam').length > 0) {
        document.addEventListener('keyup', function (e) {
            if (e.target.tagName.toLowerCase() === 'input') {
                return;
            }
            if ($('.widget.exam .actions .toggle-highlight.highlighted').length >0 || $('.widget.exam .actions .toggle-strike.striked').length >0) {
              return;
            }

            if ($('#question-note').is(':hidden')) {
                var key = e.key || e.keyCode;
                //console.log('key = '+ key);
                //console.log('examQuestionTypeID = '+examQuestionTypeID);

                if (key === 'a' || key === 'A' || key === 65 || key === 97 ) {
                    if (examQuestionTypeID == 0 || examQuestionTypeID == 1 || examQuestionTypeID == 7) {
                        answerMCSQuestionAtIndex(0);
                    } else if (examQuestionTypeID == 5) {
                        answerMCMQuestionAtIndex(0);
                    }
                } else if (key === 'b' || key === 'B' || key === 66 || key === 98) {
                    if (examQuestionTypeID == 0 || examQuestionTypeID == 1 || examQuestionTypeID == 7) {
                        answerMCSQuestionAtIndex(1);
                    } else if (examQuestionTypeID == 5) {
                        answerMCMQuestionAtIndex(1);
                    }
                } else if (key === 'c' || key === 'C' || key === 67 || key === 99) {
                    if (examQuestionTypeID == 0 || examQuestionTypeID == 1 || examQuestionTypeID == 7) {
                        answerMCSQuestionAtIndex(2);
                    } else if (examQuestionTypeID == 5) {
                        answerMCMQuestionAtIndex(2);
                    }
                } else if (key === 'd' || key === 'D' || key === 68 || key === 100) {
                    if (examQuestionTypeID == 0 || examQuestionTypeID == 1 || examQuestionTypeID == 7) {
                        answerMCSQuestionAtIndex(3);
                    } else if (examQuestionTypeID == 5) {
                        answerMCMQuestionAtIndex(3);
                    }
                } else if ( (key === 'e' || key === 'E' || key === 69 || key === 101) && 
                            examQuestionTypeID == 5 &&
                            $('.widget.exam .choices .choice').length > 4
                            ) {
                    answerMCMQuestionAtIndex(4);
                } else if ( (key === 'f' || key === 'F' || key === 70 || key === 102) && 
                            examQuestionTypeID == 5 &&
                            $('.widget.exam .choices .choice').length > 4
                            ) {
                    answerMCMQuestionAtIndex(5);
                } else if (key === 'm' || key === 'M' || key === 77 || key === 109) {
                    $('.widget.exam .actions .toggle-mark').trigger('click');
                } else if ((key === 'n' || key === 'N' || key === 78 || key === 110) && 
                            $('.action-bar .exam-session .actions .next').is(':visible')
                            ) {
                    $('.action-bar .exam-session .actions .next').trigger('click');
                } else if (key === 'p' || key === 'P' || key === 80 || key === 112) {
                    $('.action-bar .exam-session .actions .previous').trigger('click');
                }
            }
        });
    }

    // Initial progress modal setup
    setupProgressModal();

})(jQuery);
