(function ($) {
    var dialogs = {};

    // Standard Dialog
    dialogs.standard = {
        setup: function(dialogEle, onSuccess, onFail) {
            var formEle = dialogEle.find('form');
            var submitEle = dialogEle.find('button[type=submit]');
            var statusEle = dialogEle.find('.status');

            var submitting = false;
            var statusType = null;

            var setStatus = function(type, message) {
                statusType = type;

                statusEle.text(message);
                statusEle.addClass(type);
                statusEle.addClass('active');

                setTimeout($.colorbox.resize, 1);
            };

            var clearStatus = function() {
                statusEle.text('');
                statusEle.removeClass('active');
                statusEle.removeClass(statusType);

                setTimeout($.colorbox.resize, 1);
            }

            var responseHandler = function(status, data) {
                clearStatus();

                if (status !== 200) {
                    if (!data.message) {
                        if (status === 401) {
                            data.message = 'HTTP authentication is required.';
                        } else {
                            data.message = 'An unexpected server error occurred. Please try again.';
                        }
                    }

                    if (onFail) {
                        onFail(data);
                    } else {
                        setStatus('error', data.message);
                    }

                    return;
                }

                if (data.status === 301 && data.location) {
                    window.location.href = data.location;
                }

                if (onSuccess) {
                    onSuccess(data);
                }
            };

            var submitHandler = function(e) {
                e.preventDefault();

                if (submitting) {
                    return;
                }

                submitting = true;

                $.ajax({
                    method: formEle.attr('method'),
                    url: formEle.attr('action'),
                    data: formEle.serialize()
                }).done(function(data, status, xhr) {
                    responseHandler(200, data);
                }).fail(function(xhr, status, error) {
                    var data;

                    try {
                        data = $.parseJSON(xhr.responseText);
                    } catch (e) {
                        data = {};
                    }

                    responseHandler(xhr.status, data);
                }).always(function() {
                    submitting = false;
                });
            };

            submitEle.on('click', submitHandler);
            formEle.on('submit', submitHandler);
        }
    }

    // Login Dialog
    dialogs.login = {
        setup: function(dialogEle) {
            var statusEle = dialogEle.find('.status');

            var setStatus = function(type, message) {
                statusType = type;

                statusEle.text(message);
                statusEle.addClass(type);
                statusEle.addClass('active');

                setTimeout($.colorbox.resize, 1);
            };

            var successHandler = function(data) {
                window.location.href = '/dashboard?login=1';
            };

            var failHandler = function(data) {
                // Session kickoff prompt
                if (data.status === 422 && data.reason === 'concurrent_session') {
                    dialogEle.find('.state.kickoff').addClass('active');
                    dialogEle.find('.state.login').removeClass('active');
                    dialogEle.find('input[name="login[kickoff]"]').val(1);

                    setTimeout($.colorbox.resize, 1);

                    return;
                }

                // General error handling
                setStatus('error', data.message);
            }

            dialogs.standard.setup(dialogEle, successHandler, failHandler);
        },

        cleanup: function(dialogEle) {
            // Reset kickoff state
            dialogEle.find('.state.kickoff').removeClass('active');
            dialogEle.find('.state.login').addClass('active');
            dialogEle.find('input[name="login[kickoff]"]').val(0);

            // Reset status state
            dialogEle.find('.status').text('');
            dialogEle.find('.status').removeClass('active');
        }
    };

    // Register Dialog
    dialogs.register = {
        setup: function(dialogEle) {
            var successHandler = function(data) {
                window.location.href = '/dashboard';
            };

            dialogs.standard.setup(dialogEle, successHandler);
        }
    };

    // Forgot Password Dialog
    dialogs.forgot_password = {
        setup: function(dialogEle) {
            var successHandler = function(data) {
                window.location.href = '/password/forgot/success';
            };

            dialogs.standard.setup(dialogEle, successHandler);
        }
    };

    // Launch Demo Dialog
    dialogs.launch_demo = {
        setup: function(dialogEle) {
            var successHandler = function(data) {
                window.location.href = '/exam/session';
            };

            dialogs.standard.setup(dialogEle, successHandler);
        }
    };

    $.fn.dialog = function (settings) {
        settings = $.extend({}, settings);

        this.each(function () {
            var launcherEle = $(this);
            var dialogEle = $(launcherEle.attr('data-dialog-href'));
            var dialogID = launcherEle.attr('data-dialog-id');
            var dialogConfig = dialogs[dialogID];

            var colorboxSettings = {
                href: launcherEle.attr('data-dialog-href'),
                inline: true,
                open: false,
                opacity: 0.5
            };

            if (dialogConfig) {
                if (dialogEle.data('dialog-setup') !== true) {
                    dialogConfig.setup(dialogEle);

                    dialogEle.find('.dialog-close').off().on('click touchend', function(e) {
                        $.colorbox.close();
                    });

                    dialogEle.data('dialog-setup', true);
                }
            }

            if (dialogConfig && dialogConfig.cleanup) {
                colorboxSettings.onCleanup = function() {
                    dialogConfig.cleanup(dialogEle);
                };
            }

            var colorbox = launcherEle.colorbox(colorboxSettings);
        });
    }
})(jQuery);
