(function ($) {

    var launching = false;

    var launchStudySession = function(studyLauncher) {
        if (launching) {
            return;
        }

        $('.widget.study-launcher .actions a').hide();
        $('.action-bar .launch-study-session a.launch').hide();
        $('.loader').show();

        launching = true;

        var params = [];

        // CSRF
        params.push('_token=' + $.getCSRFToken());

        // Question count
        var questionCount = studyLauncher.find('input[name=focus_question_count]').val();

        if (questionCount && questionCount > 0) {
            params.push('study[focus_question_count]=' + questionCount);
        }

        // Current tab
        var currentTab = studyLauncher.find('.panel.tabbed .tab.active');

        if (currentTab.hasClass('process-group')) {
            var selectedGroup = currentTab.find('.checkbox.checked');
            var selectedGroupID = selectedGroup.attr('data-question-group-id');

            if (selectedGroupID) {
                params.push('study[focus_question_group_id]=' + selectedGroupID);
            }
        } else if (currentTab.hasClass('knowledge-area')) {
            var selectedArea = currentTab.find('.checkbox.checked');
            var selectedAreaID = selectedArea.attr('data-question-area-id');

            if (selectedAreaID) {
                params.push('study[focus_question_area_id]=' + selectedAreaID);
            }
        }  else if (currentTab.hasClass('domain')) {
            var selectedDomain = currentTab.find('.checkbox.checked');
            var selectedDomainID = selectedDomain.attr('data-question-domain-id');

            if (selectedDomainID) {
                params.push('study[focus_question_domain_id]=' + selectedDomainID);
            }
        } else if (currentTab.hasClass('chapter')) {
            var selectedChapter = currentTab.find('.checkbox.checked');
            var selectedChapterID = selectedChapter.attr('data-question-chapter-id');

            if (selectedChapterID) {
                params.push('study[focus_question_chapter_id]=' + selectedChapterID);
            }
        } else if (currentTab.hasClass('keyword')) {
            var keyword = currentTab.find('input[name=focus_keyword]').val();

            if (keyword) {
                params.push('study[focus_keyword]=' + keyword);
            }
        } else if (currentTab.hasClass('question-id')) {
            var questionID = currentTab.find('input[name=focus_question_id]').val();

            if (questionID) {
                params.push('study[focus_question_id]=' + questionID);
            }
        }

        $.ajax({
            url: '/study/actions/launch',
            method: 'POST',
            data: params.join('&')
        }).done(function(response) {
            launching = false;
            $('.loader').hide();
            console.log('Launcing done... response = '+response)
            if (response.ok == 'ok') {
                window.location.href = response.data;
            } else {
                console.log('Launching response Error: '+response);
            }
        }).fail(function(xhr) {
            launching = false;
            $('.loader').hide();

            // Handle no session error
            if (xhr.status === 401) {
                window.alert('Your session is no longer active. Please sign in again.');
                window.location.reload();
                return;
            }

            // Handle unexpected server error
            if (xhr.status === 500) {
                window.alert('An unexpected server error occurred. Please try again.');
                return;
            }

            alert('No questions were found matching your specified study focus. Please try again.')
        });
    };

    $('.widget.study-launcher .panel.focus .panel-header a').off().on('click touchend', function(e) {
        var tabName = $(this).attr('data-tab');

        if (tabName === 'question-id') {
            $('.widget.study-launcher .panel.structure').hide();
        } else {
            $('.widget.study-launcher .panel.structure').show();
        }
    });

    $('.widget.study-launcher').each(function(index, studyLauncher) {
        var studyLauncher = $(studyLauncher);

        $('.action-bar .launch-study-session a.launch').off().on('click touchend', function(e) {
            e.preventDefault();

            launchStudySession(studyLauncher);
        });

        $('.widget.study-launcher .tab.keyword input').off().on('keypress', function(e) {
            var input = $(this);

            if (input.val().length === 0) {
                return;
            }

            var keyCode = e.keyCode || e.which;

            if (keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();

                launchStudySession(studyLauncher);
            }
        });

        $('.widget.study-launcher .tab.question-id input').off().on('keypress', function(e) {
            var input = $(this);

            if (input.val().length === 0) {
                return;
            }

            var keyCode = e.keyCode || e.which;

            if (keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();

                launchStudySession(studyLauncher);
            }
        });
    });

})(jQuery);
