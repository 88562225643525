(function ($) {

    $.getCSRFToken = function() {
        return $('meta[name="csrf-token"]').attr('content');
    };

    $.fn.countdown = function(settings, delay) {
        //console.log('countdown = ' + settings);
        settings = $.extend({}, settings);

        var ele = $(this);
        var countdown = parseInt(ele.attr('data-countdown'));

        var deadline = new Date();// This needs to be the same as the expires_at field in the session
        deadline.setSeconds(deadline.getSeconds() + countdown);
        //console.log('countdown: deadline = '+deadline);

        var stopwatch;

        var timeLeft = function(end) {
            var delta = end - new Date();

            var seconds = Math.floor((delta / 1000) % 60);
            //var minutes = Math.floor((delta / 1000 / 60) % 60);
            var minutes = Math.floor(delta / 1000 / 60);
            var hours = Math.floor((delta / (1000 * 60 * 60)) % 24);
            var days = Math.floor(delta / (1000 * 60 * 60 * 24));

            return {
                'total': delta,
                'days': days,
                'hours': hours,
                'minutes': minutes,
                'seconds': seconds
            };
        }

        var pad = function(num, size) {
            var s = num + '';
            while (s.length < size) s = '0' + s;
            return s;
        }

        var tick = function() {
            //console.log('countdown: tick');
            var tl = timeLeft(deadline);

            ele.text(pad(tl.minutes, 2) + ':' + pad(tl.seconds, 2));

            if (tl.total > 0) {
                //timerId = window.setTimeout(tick, 900);
                if (tl.minutes < 5){
                    $('#exam-timer').css( "background-color", "red" );
                }
                timer.resume();
            }
            else {
                // redirect user to Finish screen
                window.location.href = '/exam/session/finish';
            }
        };

        //tick();
        function Timer(callback, delay) {
            //console.log('countdown: Timer():');
            //console.log('countdown: Timer(): callback = '+callback);

            var timerId, start, remaining = delay;

            dl = deadline;
            //console.log('countdown: Timer(): dl = '+dl);

            this.pause = function() {
                //console.log('countdown: Timer(): pause():');
                window.clearTimeout(timerId);
                //remaining -= new Date() - start;
                //console.log('countdown: Timer(): pause(): start = '+start);
                //console.log('countdown: Timer(): pause(): remaining = '+remaining);
            };

            this.resume = function() {
                //console.log('countdown: Timer(): resume():');
                //start = new Date();
                start = dl;
                //console.log('countdown: Timer(): resume(): start = '+start);
                //console.log('countdown: Timer(): resume(): remaining = '+remaining);

                window.clearTimeout(timerId);
                timerId = window.setTimeout(callback, remaining);
            };

            this.resume();
        }

        timer = new Timer(function(){
            //alert("Done!");
            //console.log('countdown: Timer(): Done');
            tick();
        }, 900);
    };

    $.fn.countup = function(settings) {
        settings = $.extend({}, settings);

        this.each(function() {
            var ele = $(this);
            var countup = parseInt(ele.attr('data-countup'));

            var start = new Date();
            start.setSeconds(start.getSeconds() - countup);

            var stopwatch;

            var timeSince = function(start) {
                var delta = new Date() - start;

                var seconds = Math.floor((delta / 1000) % 60);
                var minutes = Math.floor((delta / 1000 / 60) % 60);
                var hours = Math.floor((delta / (1000 * 60 * 60)) % 24);
                var days = Math.floor(delta / (1000 * 60 * 60 * 24));

                return {
                    'total': delta,
                    'days': days,
                    'hours': hours,
                    'minutes': minutes,
                    'seconds': seconds
                };
            }

            var pad = function(num, size) {
                var s = num + '';
                while (s.length < size) s = '0' + s;
                return s;
            }

            var tick = function() {
                var tl = timeSince(start);

                ele.text(pad(tl.hours, 2) + ':' + pad(tl.minutes, 2) + ':' + pad(tl.seconds, 2));

                if (tl.total > 0) {
                    setTimeout(tick, 900);
                }
            };

            tick();
        });
    };

    $.fn.tabPanel = function (settings) {
        settings = $.extend({}, settings);

        this.each(function () {
            var ele = $(this);

            var tabs = ele.find('.tab');

            ele.find('.panel-header a').off().on('click touchend', function(e) {
                e.preventDefault();

                var tabName = $(this).attr('data-tab');
                var liParent = $(this).parent('li');
                var liSiblings = $(liParent).siblings('li');
                var divParent = $(this).parents('div .panel-header')
                var blockTabs = divParent.find('.tab[data-tab!=' + tabName + ']');
                var selectedTab = $(ele).find('.tab[data-tab=' + tabName + ']');

                tabs.removeClass('active')
                liSiblings.removeClass('active');
                liParent.addClass('active');

                blockTabs.removeClass('active');
                selectedTab.addClass('active');
                
                if (divParent[0].hasAttribute('id') == false) {
                    var lowerPanels = ele.next().find('.panel-lower');
                    var lowerPanelHeaders = ele.next().find('.panel-header');
                    var lowerPanelBlocks = ele.next().find('.panel-block');
                    var selectedPanel = ele.next().find('#panel-lower-'+tabName);
                    var selectedPanelHeader = ele.next().find('#panel-header-'+tabName);
                    var selectedPanelBlock = ele.next().find('#panel-block-'+tabName);
                    lowerPanels.each(function( item ) {
                        if ($(this).is(':hidden')){
                            $(this).show();
                        }
                        if ($(this).not('[id*='+tabName+']')) {
                            $(this).hide();
                        }
                    });
                    lowerPanelHeaders.each(function( item ) {
                        if ($(this).is(':hidden')){
                            $(this).show();
                        }
                        if ($(this).not('[id*='+tabName+']')) {
                            $(this).hide();
                        }
                    });
                    lowerPanelBlocks.each(function( item ) {
                        if ($(this).is(':hidden')){
                            $(this).show();
                        }
                        if ($(this).not('[id*='+tabName+']')) {
                            $(this).hide();
                        }
                    });
                    
                    selectedPanel.show();
                    selectedPanelHeader.show();
                    selectedPanelBlock.show();
                }
            });
        });
    };

    $.fn.checkboxes = function (settings) {
        settings = $.extend({}, settings);

        this.each(function () {
            var ele = $(this);

            var checkboxes = ele.find('.checkbox');

            var checkboxGroup = null;
            if (ele.attr('data-checkbox-group')) {
                checkboxGroup = ele.attr('data-checkbox-group');
            }

            var groupedCheckboxes = null;
            if (checkboxGroup) {
                groupedCheckboxes = $('[data-checkbox-group=' + checkboxGroup + '] .checkbox');
            }

            var checkboxBehavior = 'normal';
            if (ele.attr('data-checkbox-behavior')) {
                checkboxBehavior = ele.attr('data-checkbox-behavior');
            }

            checkboxes.on('click', function(e) {
                e.preventDefault();

                var checkbox = $(this);
                var wasChecked = checkbox.hasClass('checked');

                if (checkbox.hasClass('disabled')) {
                    return;
                }

                if (checkboxBehavior === 'min-1' && wasChecked && groupedCheckboxes && groupedCheckboxes.find('.checked').length - 1 <= 0) {
                    return;
                }

                checkboxes.removeClass('checked')
                checkboxes.addClass('unchecked');

                if (groupedCheckboxes) {
                    groupedCheckboxes.removeClass('checked');
                    groupedCheckboxes.addClass('unchecked');
                }

                if (!wasChecked) {
                    checkbox.removeClass('unchecked');
                    checkbox.addClass('checked');
                }
            });
        });
    };

    $.fn.examChoices = function (settings) {
        settings = $.extend({}, settings);

        this.each(function () {
            var ele = $(this);

            var choices = ele.find('.choice');
            var radios = ele.find('.radio');

            var selectChoice = function(raw) {
                //console.log("selectChoice()");
                var selectedChoice = $(raw);
                var selectedRadio = selectedChoice.find('.radio');

                // Uncheck if already selected
                if (selectedChoice.hasClass('selected')) {
                    console.log("util: selectChoice(): already selected");
                    selectedChoice.removeClass('selected');
                    selectedRadio.removeClass('checked');
                    selectedRadio.addClass('unchecked');

                    return;
                }

                radios.removeClass('checked');
                radios.addClass('unchecked');

                selectedRadio.removeClass('unchecked');
                selectedRadio.addClass('checked');

                choices.removeClass('selected');
                selectedChoice.addClass('selected');
            };

            if (radios.length > 0 ) {
                choices.off('click.checkRadio').on('click.checkRadio', function(e) {
                    console.log("on: choices clicked");
                    e.preventDefault();
                    selectChoice(this);
                });
            }

            choices.off('choice:select').on('choice:select', function(e) {
                console.log("on: choice:select");
                selectChoice(this);
            });
        });
    };

})(jQuery);
