require('./bootstrap');

(function ($) {

    $('[data-submit-form]').off().on('click touchend', function(e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });

    // Exam Builds
    $('.exam-builder').each(function(index, examBuilder) {
        var examBuilder = $(examBuilder);

        var rowTable = examBuilder.find('table');
        var rowTemplate = examBuilder.find('.question-row-template');

        var renumber = function() {
            rowTable.find('.question-row').each(function(index) {
                $(this).find('.question-position').text(index + 1);
            });
        };

        var addRow = function(afterRow) {
            var newRow = rowTemplate.clone();

            newRow.removeClass('question-row-template');
            newRow.addClass('question-row');

            newRow.insertAfter(afterRow);

            newRow.find('input').focus();

            renumber();
        };

        var removeRow = function(row) {
            row.remove();

            renumber();
        };

        examBuilder.delegate('.question-row .actions [data-action="add"]', 'click', function(e) {
            e.preventDefault();

            var btn = $(this);
            var row = btn.parents('.question-row');

            addRow(row);
        });

        examBuilder.delegate('.question-row .actions [data-action="remove"]', 'click', function(e) {
            e.preventDefault();

            var btn = $(this);
            var row = btn.parents('.question-row');

            removeRow(row);
        });
    });

    $('#template-product').on('change', function(){
        var product_id = $(this).val();

        if (!product_id) {
            return;
        }

        var template_group = $('#template-group').val('');

        template_group.find('option').prop('disabled', true).prop('selected', false).hide();
        template_group.find('option[data-product-id=' + product_id + ']').prop('disabled', false).show();
    }).trigger('change');

    $('select.locale-select').on('filterProduct', function(e, product) {
        var select = this;
        var _method = "GET";
        var data = [];

        //data.push('_token=' + _token);
        data.push('product=' + product);

        $.when(
            $.ajax({
                url: '/admin/locales',
                data: data.join('&'),
                method: _method,
                dataType: 'json',
            })
        ).then(function(locales) {
            var product_locales = locales;

            $('option', select).each(function(){
                var $option = $(this),
                    value = $option.data('localeId') || $option.val();

                if (!value) {
                    return true;
                }

                if ($.inArray(parseInt(value, 10), product_locales) === -1) {
                    $option.prop('disabled', true).prop('selected', false).hide();
                } else {
                    $option.prop('disabled', false).show();
                }
            });
        });
    });

    $('select.product-select').on('change', function(){
        var product_slug = $(this).find('option:selected').data('productSlug'),
            $locale_select = $(this).data('localeSelect') ? $($(this).data('localeSelect')) : $('.locale-select');

        $locale_select.trigger('filterProduct', [ product_slug ]);
    });

})(jQuery);
